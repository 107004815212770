#video {
    min-height: 100vh;
}
.videoContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 15px;
}

.youtube {
    width: 25vw;
    min-width: 400px;
    cursor: pointer;
}

/* Only for use when awaiting videos */
.outerPlaceHolder {
    display: flex;
    justify-content: center;
    align-items: center;
    /* height: calc(100vh - 80px); */
    height: 60vh;
    width: 100%;

}