.paddingTop {
    padding-top: 80px;
}
.borderBox {
    box-sizing: border-box;
}

.component {
    display: block;
    min-height: 100vh;
    padding-top: 80px;
    box-sizing: border-box;
    /* border: thin solid red; */
}

.section-title {
    /* border: thin solid red; */
    margin-bottom: 4vh;
    margin-left: 15px;
    text-align: left;
    font-family: "Oswald";
    color: var(--nav-color);
    text-shadow: 3px 3px 3px var(--nav-hover);
    text-size-adjust: auto;
    font-size: 24px;
}
@media screen and (min-width: 1201px) {
    /*desktop size*/
    .section-title {
        font-size: 36px;
    }
}

