#contact {
    min-height: 100vh;
}
.contactParent {
    display: flex;
    flex-direction: row; 
    justify-content: space-around;
    
}

#bandcamp-button {
    border: thin solid #FCF3CB;
    border-radius: 10px; 
    background-color: #0394b7;
    margin: 5vh;
    background-image: url('../../public/pics/site-pics/BandcampFollow.png');
    background-position: center;
    background-size: cover;
}

#contact-content {
    border: thin solid var(--nav-color);
    background-color: var(--background-grey);
    opacity: .9;
    border-radius: 10px;
    margin-bottom: 2vh;
    width: 40%;
    padding: 10px;

    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.formParent {
    width: 40%;
}

#contactForm {
    border: thin solid var(--nav-color);
    font-family: "Oswald";
    background-color: var(--background-grey);
    opacity: .9;
    border-radius: 10px;
    margin-bottom: 2vh;
    padding: 10px;
    width: 40%;
}

#bc-button {
    width: 180px;
}

/* #merch {
    font-family: 'Oswald';
    text-align: center;
    color: var(--nav-color);
} */

.content-container {
    padding: 1vh; 
    font-size: 1.5em;
    text-align: center;
}


.socialIconContainer {
    padding: 5vh;
}

.socialIcon:hover {
    transform: translateY(-3px);
}

.socialIcon {
    margin: 0 1rem;
    transition: transform 250ms;
    display: inline-block;
}

#form-button {
    margin-right: auto;
    margin-left: auto;
    display: block;
    background-image: linear-gradient(to bottom left, var(--nav-hover), brown);
    color: var(--nav-color);
    border: thin solid var(--nav-hover);
    font-size: 1.1em;
    text-shadow: 2px 2px 2px var(--text-shadow);
}

#form-button:hover {
    transition: all .5s ease-out;
    transform: scale(1.1);
}

.formFields {
    border: none !important;
    outline: none !important;
    box-shadow: none !important;
}
@media screen and (max-width: 1000px) {
    /*desktop size*/
    .contactParent {
        align-items: center;
        flex-direction: column;
    }
    #contactForm {
        width: 80vw;
    }
    #contact-content {
        width: 80vw;
    }
    #bandcamp-container {
        padding: 5px;
    }

}
