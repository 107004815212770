/* .carousel {
    border-radius: 20px;
    width:1200px;
    height:850px;
    margin: auto;
} */

#carousel img{
    /* width: 250px; */
    height: 80vh;
    object-fit: cover;
    object-fit: contain;
    padding-bottom: 30px;
}
#carousel {
    z-index: 0;
}
.caption {
    font-size: x-small;
    text-shadow: 2px 2px 2px red;
}